  /* ScrollBar */
  /* width */
  ::-webkit-scrollbar {
    width: 8px;
    border-radius: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--primary);
    border-radius: 4px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: var(--primary-dark);
  }

  @media (max-width: 800px) {
    ::-webkit-scrollbar {
      width: 4px;
      border-radius: 2px;
    }
  
    ::-webkit-scrollbar-track {
      border-radius: 2px;
    }
  
    ::-webkit-scrollbar-thumb {
      border-radius: 2px;
    }
  }

  ::selection {
    color: white;
    background: var(--primary);
}