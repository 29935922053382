*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html {
    font-size: 62.5%;
}

@media (max-width: 800px) {
	html {
        font-size: 52.5%;
        overflow-x: hidden;
    }
}

body {
    box-sizing: border-box;
    font-family: 'Sen', sans-serif;
    background-color: #fcfcfc;
    color: #05012e;
    overflow-x: hidden;
}

